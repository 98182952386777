import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20240409-Rate-Sheet.pdf";
const date = "09.04.2024";

const Article = () => (
    <NewsArticleLayout href={href} date={date} isNew={isNew}>
        <h1 className="page-title">Changes to our mortgage products</h1>
        <p>With effect from Tuesday 9 April 2024 we will be making the following changes to our range:</p>
        <p><strong>Summary of changes</strong></p>
        <p><strong>UK Residential Existing Customer Switching Range</strong></p>
        
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60% LTV has decreased</li>
            <li>5 Year Fixed Premier Exclusive at 60% LTV has decreased</li>
        </ul>
        <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60% LTV has decreased</li>
            <li>5 Year Fixed Premier Exclusive at 60% LTV has decreased</li>
        </ul>
        <p><strong>UK Residential First Time Buyer Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% have decreased</li>
            <li>5 Year Fixed Standard at 60% has decreased</li>
            <li>5 Year Fixed Premier Exclusive at 60% LTV has decreased</li>
        </ul>
        <p><strong>UK Residential First Time Buyer Energy Efficient Home Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% have decreased</li>
            <li>5 Year Fixed Standard at 60% has decreased</li>
        </ul>
        <p><strong>UK Residential Home Mover Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% have decreased</li>
            <li>5 Year Fixed Standard at 60% has decreased</li>
            <li>5 Year Fixed Premier Exclusive at 60% LTV has decreased</li>
        </ul>
        <p><strong>UK Residential Home Mover Energy Efficient Home Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% have decreased</li>
            <li>5 Year Fixed Standard at 60% has decreased</li>
        </ul>
        <p><strong>UK Residential Remortgage Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Premier Exclusive at 70% and 75% LTV have decreased</li>
        </ul>
        <p><strong>UK Residential Remortgage Energy Efficient Home Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 70% and 75% LTV have decreased</li>
        </ul>
        <p><strong>UK Residential Remortgage Cashback Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 70% and 75% LTV have decreased</li>
        </ul>
        <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
        <ul>
            <li>5 Year Fixed Fee Saver at 65% LTV has decreased</li>
        </ul>
        <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
        <ul>
            <li>5 Year Fixed Fee Saver at 65% LTV has decreased</li>
        </ul>
        <p><strong>UK Buy to Let Purchase Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
        </ul>
        <p><strong>UK Buy to Let Remortgage Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60% and 65% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60% and 65% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 65% LTV has decreased</li>
        </ul>
        <p><strong>International Residential Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60% 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Premier Exclusive at 70% and 75% LTV have decreased</li>
        </ul>
        <p>There are no changes to any other interest rates.</p>
        <p>An updated mortgage rate sheet will be published on Tuesday 9 April 2024 to reflect these changes.</p>
    </NewsArticleLayout>
);

export default Article;